import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: "en",
    resources: {
      en: {
        translation: {
          "只有三天 ♡ 全館滿千免運費": "Only 3 days ♡ Free shipping for purchaces over $1,000",
          "新品上市": "New Arrival",
          "商品分類": "Categories",
          "會員專區": "Members",
          "潮流話題": "Trendy Topics",
          "此頁面還在建立中": "Coming Soon",
          "返回主頁": "Back to Homepage",
          "目錄": "Nav Bar",
          "我的賬戶": "My Account",
          "關於 Floral Accessories": "About Floral Accessories",
          "聯絡我們": "Contact Us",
          "耳環": "Earrings",
          "項鏈": "Necklaces",
          "手環": "Bracelets",
          "戒指": "Rings",
          "髮夾": "Hairpins",
          "帽子": "Hats",
          "關於我們": "About Us",
          "品牌故事": "Brand Story",
          "設計師們": "Designers",
          "門市資訊": "Store Info",
          "購物說明": "Shopping Guide",
          "付款方式": "Payment Method",
          "貨物運送": "Shipping",
          "退換貨方式": "Returns",
          "客服資訊": "Customer Service",
          "客服留言": "Leave Us a Message",
          "常見問題": "FAQ",
          "追蹤我們": "Follow Us",
          "圖片來源": "Image Source",
          "網頁製作": "Developed by",
          "件商品": "items",
          "排序": "Sort By",
          "價格低至高": "Price: Low to high",
          "價格高至低": "Price: High to low",
          "商品特色": "Product Features",
          "新增至購物車": "Add to cart",
          "立即結賬": "Checkout",
          "搜尋商品": "Search Products",
          "我的購物車": "My Cart",
          "商品": "Products",
          "單價": "Unit Price",
          "數量": "Quantity",
          "總金額": "Price",
          "小計": "Subtotal",
          "運費": "Shipping",
          "總額": "Total Price",
          "前往結賬": "Proceed to checkout",
          "先登入再結賬": "Login and Checkout",
          "訪客結賬": "Guest Checkout",
          "目前您的購物車沒有商品，請點選前往購物，開始選購商品": "There are currently no items in your shopping cart.",
          "開始購物": "Start Shopping",
          "查看購物車": "Go to cart",
          "實體店地址": "Store Address",
          "台北市信義區信義路五段7號": "No.7, Section 5, Xinyi Road, Xinyi District, Taipei",
          "客服專綫": "Customer Service Hotline",
          "客服信箱": "Customer Service Email",
          "我們希望聽到您的意見": "We would like to hear from you",
          "你的名字": "Name",
          "電子信箱": "Email",
          "輸入評論": "Comment",
          "送出": "Send",
          "謝謝您的意見！我們會儘快回覆您。": "Thank you for your feedback!",
          "一鍵登入": "One-click Login",
          "官網會員登入": "Login with Email and Password",
          "請輸入您的電子信箱和密碼": "Please enter your email and password",
          "密碼": "Password",
          "登入": "Login",
          "立即註冊": "Sign up",
          "立即註冊即可獲得官網會員獨家優惠": "Register now to get exclusive discounts for members",
          "賬戶名稱": "User ID",
          "確認密碼": "Confirm Password",
          "註冊": "Sign up",
          "訂單記錄": "My Orders",
          "願望清單": "My Wishlist",
          "登出": "Sign Out",
          "登入即可永久儲存願望清單，並在任何裝置上管理。": "Login to permanently save your wishlist and manage it on any device.",
          "我的訂單記錄": "My Orders",
          "項訂單": "Orders",
          "你尚未有任何訂單記錄": "You don't have any orders yet",
          "訂單日期": "Date",
          "訂單編號": "Order ID",
          "訂單總金額": "Total Price",
          "我的願望清單": "My Wishlist",
          "你沒有加入商品到願望清單": "You have not added items to your wishlist",
          "移除": "Remove",
          "已新增至你的願望清單": "Added to your wishlist",
          "查看願望清單": "Check wishlist",
          "此頁面不存在": "Oops...Page Not Found",
          "搜尋關於 \"{{searchText}}\" 的結果": "Search result of \"{{searchText}}\"",
          "查無相關 \"{{searchText}}\" 的結果": "No result found for \"{{searchText}}\"",
          "全部商品": "All Products"
        }
      },
      zh: {
        translation: {
          "只有三天 ♡ 全館滿千免運費": "只有三天 ♡ 全館滿千免運費",
          "新品上市": "新品上市",
          "商品分類": "商品分類",
          "會員專區": "會員專區",
          "潮流話題": "潮流話題",
          "此頁面還在建立中": "此頁面還在建立中",
          "返回主頁": "返回主頁",
          "目錄": "目錄",
          "我的賬戶": "我的賬戶",
          "關於 Floral Accessories": "關於 Floral Accessories",
          "聯絡我們": "聯絡我們",
          "耳環": "耳環",
          "項鏈": "項鏈",
          "手環": "手環",
          "戒指": "戒指",
          "髮夾": "髮夾",
          "帽子": "帽子",
          "關於我們": "關於我們",
          "品牌故事": "品牌故事",
          "設計師們": "設計師們",
          "門市資訊": "門市資訊",
          "購物說明": "購物說明",
          "付款方式": "付款方式",
          "貨物運送": "貨物運送",
          "退換貨方式": "退換貨方式",
          "客服資訊": "客服資訊",
          "客服留言": "客服留言",
          "常見問題": "常見問題",
          "追蹤我們": "追蹤我們",
          "圖片來源": "圖片來源",
          "網頁製作": "網頁製作",
          "件商品": "件商品",
          "排序": "排序",
          "價格低至高": "價格低至高",
          "價格高至低": "價格高至低",
          "商品特色": "商品特色",
          "新增至購物車": "新增至購物車",
          "立即結賬": "立即結賬",
          "搜尋商品": "搜尋商品",
          "我的購物車": "我的購物車",
          "商品": "商品",
          "單價": "單價",
          "數量": "數量",
          "總金額": "總金額",
          "小計": "小計",
          "運費": "運費",
          "總額": "總額",
          "前往結賬": "前往結賬",
          "先登入再結賬": "先登入再結賬",
          "訪客結賬": "訪客結賬",
          "目前您的購物車沒有商品，請點選前往購物，開始選購商品": "目前您的購物車沒有商品，請點選前往購物，開始選購商品",
          "開始購物": "開始購物",
          "查看購物車": "查看購物車",
          "實體店地址": "實體店地址",
          "台北市信義區信義路五段7號": "台北市信義區信義路五段7號",
          "客服專綫": "客服專綫",
          "客服信箱": "客服信箱",
          "我們希望聽到您的意見": "我們希望聽到您的意見",
          "你的名字": "你的名字",
          "電子信箱": "電子信箱",
          "輸入評論": "輸入評論",
          "送出": "送出",
          "謝謝您的意見！我們會儘快回覆您。": "謝謝您的意見！我們會儘快回覆您。",
          "一鍵登入": "一鍵登入",
          "官網會員登入": "官網會員登入",
          "請輸入您的電子信箱和密碼": "請輸入您的電子信箱和密碼",
          "密碼": "密碼",
          "登入": "登入",
          "立即註冊": "立即註冊",
          "立即註冊即可獲得官網會員獨家優惠": "立即註冊即可獲得官網會員獨家優惠",
          "賬戶名稱": "賬戶名稱",
          "確認密碼": "確認密碼",
          "註冊": "註冊",
          "訂單記錄": "訂單記錄",
          "願望清單": "願望清單",
          "登出": "登出",
          "登入即可永久儲存願望清單，並在任何裝置上管理。": "登入即可永久儲存願望清單，並在任何裝置上管理。",
          "我的訂單記錄": "我的訂單記錄",
          "項訂單": "項訂單",
          "你尚未有任何訂單記錄": "你尚未有任何訂單記錄",
          "訂單日期": "訂單日期",
          "訂單編號": "訂單編號",
          "訂單總金額": "訂單總金額",
          "我的願望清單": "我的願望清單",
          "你沒有加入商品到願望清單": "你沒有加入商品到願望清單",
          "移除": "移除",
          "已新增至你的願望清單": "已新增至你的願望清單",
          "查看願望清單": "查看願望清單",
          "此頁面不存在": "此頁面不存在",
          "搜尋關於 \"{{searchText}}\" 的結果": "搜尋關於 \"{{searchText}}\" 的結果",
          "查無相關 \"{{searchText}}\" 的結果": "查無相關 \"{{searchText}}\" 的結果",
          "全部商品": "全部商品"
        }
      }
    }
  })
